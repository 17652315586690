var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 pt-2"},[_c('v-subheader',[_vm._v("Euros Impagados por canal")]),_c('v-divider',{staticClass:"mb-4"}),(_vm.value !== null)?_c('donut-chart',{attrs:{"options":{
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
    },"chartdata":_vm.value}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }